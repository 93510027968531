export default function Loading() {
    return (
        <div className="loadingPage">
            <div className="linesparent">
                <div className="lines linetop"></div>
                <div className="lines lineright"></div>
                <div className="lines linebottom"></div>
                <div className="lines lineleft"></div>
                <div className="lines linebg"></div>
            </div>
        </div>
    )
}