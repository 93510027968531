import { useEffect, useState } from 'react';
import './reset.css';
import './App.css';
import './Mobile.css';
import MainPage from './components/MainPage';
import Loading from './components/Loading';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

function App() {
    var iframe;
    const [data, setData] = useState(null);
    const [cat, setCat] = useState("table");
    const [model, setModel] = useState(null);
    const [index, setIndex] = useState(0);     

    const categories = {
        table: { model: ["table_Sculpture", "table_Soul", "table_Timeless"], fr: "tables", en: "table" },
        chair: { model: ["chair_Anne_I", "chair_Anne_II", "chair_Beth", "chair_Eva", "chair_Mia", "chair_Olivia", "chair_Sara_I", "chair_Sara_II"], fr: "chaises", en: "chair" },
        bench: { model: ["bench_Sponge"], fr: "bancs", en: "bench" },
        buffet: { model: ["buffet_Absolute", "buffet_Vision"], fr: "buffets", en: "buffet" },
        bed: { model: ["bed_Nest", "bed_Nuance", "bed_Victoria"], fr: "lits", en: "bed" },
        bedroomStorage: { model: ["bedroomStorage_Absolute", "bedroomStorage_Vision"], fr: "meubles de rangement", en: "bedroom storage" },
        mediaConsole: { model: ["mediaConsole_Absolute"], fr: "meubles audio-vidéo", en: "media console" },
        accentPiece: { model: ["accentPiece_Beam", "accentPiece_Edge", "accentPiece_Leo", "accentPiece_MixItUp", "accentPiece_Sara", "accentPiece_SaraPlus", "accentPiece_TamTam"], fr: "accessoires", en: "accent piece" },
        stool: { model: ["barstool_Bocca", "barstool_Olivia", "barstool_Wish"], fr: "tabourets", en: "stool" },
    }

    // ----------------------------------------------------------- INIT APP
    function initIframe() {
        iframe = document.getElementById('appIframe');
        var i = iframe.contentWindow;
        i.addEventListener("initiatedApp", OnInitApp, false);
        /*i.addEventListener("OnModelBegin", OnModelBegin, false);
        i.addEventListener("OnModelEnd", OnModelEnd, false);*/
    }
    const OnInitApp = () => {
        setData(iframe.contentWindow.getData());
        iframe.contentWindow.setBackgroundColor("#F1EEEC");
    }

    useEffect(() => {
        //console.log(data);
    }, [data])
    // ------------------------------------------------------ INIT APP [END]

    /*useEffect(() => {
        if (model !== null) {
            console.log(model.code);
        }
    }, [model])*/

    /*const OnModelEnd = () => {
        console.log("Model End");
    }
    const OnModelBegin = () => {
        console.log("Model Begin");
    }*/

    const returnBack = () => {
        setIndex(0)
        setModel(null)
    }


    return (
        <div className="App">
            {data === null && <Loading />}
            {data !== null && <MainPage
                categories={categories}
                active={cat}
                setActive={setCat}
                iframe={iframe}
                data={data}
                model={model}
                setModel={setModel}
                setIndex={setIndex}
                index={index}
            />
            }
            {model !== null && <button className='desktop' onClick={() => returnBack()} id="return"><div>{<KeyboardBackspaceRoundedIcon />}<p>Retour</p></div></button>}
            {/*<iframe id="appIframe" onLoad={() => initIframe()} src='./app/index.html?code=false' loading="lazy" ></iframe>*/}
            <iframe id="appIframe" onLoad={() => initIframe()} src='./app/index.html?code=false' loading="lazy" ></iframe>
        </div>
    );
}

export default App;
