export default function MainMenu(props) {

    const iframe = document.getElementById('appIframe').contentWindow;

    const setContent = (e) => {
        const el = e.target.dataset.id;
        props.setActive(el);
    }

    function searchByID(id) {
        var rtn = null;
        props.data.meshes.models.map((e, i) => {
            if (e.nameID == id) {
                rtn = e;
            }
        });
        return rtn;
    }

    return (
        <div id="mainMenu">
            <div id="menu" className="desktop">
                <img className="logo" src="../images/trica_logo.png" alt="logo trica" />
                <div className="gr_btn">
                    {
                        Object.entries(props.categories).map(([el,i],index)=>{
                            const isActive = props.active===el?" active":"";
                            return <button key={index} onClick={(e) => setContent(e)} data-id={el} className={"btn " + isActive}>{i.fr}</button>
                        })
                    }                    
                </div>
            </div>
            <div id="content">
                <p id="title">{props.categories[props.active].fr}</p>
                <div className="gr_btn_icon">
                    {
                        props.categories[props.active].model.map((e, i) => {
                            const model = searchByID(e)
                            var name = e.substring(e.indexOf("_") + 1);
                            name = name.replace("_", " ");
                            name = name.replace(/([A-Z])/g, ' $1').trim()
                            name = name.replace("I I", 'II')
                            return <Icon iframe={iframe} setIndex={props.setIndex} key={i} name={name} img={e} model={model} setModel={props.setModel} id={model.code} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function Icon(props) {
    const setMesh = (model) => {
        props.setModel(model)
        props.setIndex(1)
        //console.log(props.iframe);
        props.iframe.loadModel(model.code);
    }
    return (
        <div onClick={() => setMesh(props.model)} data-id={props.id} className="btn_icon">
            <img src={"./images/thumbnails/models/" + props.img + ".png"} alt={props.img} />
            <h3>{props.name}</h3>
            <div></div>
        </div>

    )
}