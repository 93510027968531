import { useCallback, useState } from "react";

export default function MobileBottom(props) {
    //const [mobileMode, setMobileMode] = useState("3d");
    const [btn, setBtn] = useState([]);

    const setMobileMode = useCallback((mode) => {
        props.setMobileMode(mode)
    }, [btn]);
    return (
        <div id='mobileBottom' className='mobile'>
            <button btn={btn} onClick={() => setMobileMode("3d")} className={props.mobileMode === "3d" ? "active" : ""}>Modèle 3D</button>
            <span></span>
            <button btn={btn} onClick={() => setMobileMode("options")} className={props.mobileMode === "options" ? "active" : ""}>Configuration</button>
        </div>
    )

}