import React, { useEffect, useRef, useState } from "react";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';


//var currCat = [];
var initVal = 0;
export default function RightMenu(props) {
    const iframe = document.getElementById('appIframe').contentWindow;
    var name = props.model ? props.model.nameID.substring(props.model.nameID.indexOf("_") + 1) : "";
    name = name.replace("_", " ");
    name = name.replace(/([A-Z])/g, ' $1').trim()
    name = name.replace("I I", 'II')

    //const hide = mobileMode === "3d" ? " hide" : "";

    const catMtl = () => {
        var rtn = [];
        var tmp = {};
        //currCat = [];
        props.model?.options[0].materials.map((e, i) => {
            const num = e.type.replace(/[^0-9]/g, '');
            if (num == 1) {
                tmp = {
                    id: e.type,
                    name: e.typeName,
                    opt: e.switch
                }
            } else if (num == 2) {
                var tID = [e.type]
                var tNAME = [e.typeName]
                var tOPT = [e.switch]
                if (tmp.id != null) {
                    tID.unshift(tmp.id);
                    tNAME.unshift(tmp.name);
                    tOPT.unshift(tmp.opt);
                }
                rtn.push({
                    id: tID,
                    name: tNAME,
                    opt: tOPT
                })
            } else {
                rtn.push({
                    id: [e.type],
                    name: [e.typeName],
                    opt: [e.switch]
                })
            }
        })
        //console.log(rtn);
        initVal++;
        return rtn.reverse();
    }

    return (
        <React.Fragment>
            <div id="config">
                <div className="titles">
                    <div>
                        <h4>{props.categories[props.active].fr}</h4>
                        <h2>{name}</h2>
                    </div>
                    <span onClick={() => props.setMobileMode("3d")} className="mobile gotoBtn"><KeyboardBackspaceRoundedIcon /></span>
                </div>
                <div id="configurator">
                    {
                        catMtl().map((e, i, a) => {
                            return <Tab activeCat={props.active} iframe={iframe} model={props.model} mtls={props.data.materials} length={a.length} el={e} key={i} />
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
//
function Tab(props) {
    const [currCat, setCurrCat] = useState(0);


    const setCat = (index) => {
        setCurrCat(index)
    }
    //console.log(refs.current[currCat]?.offsetWidth);
    return (
        <div className="category">
            <div className="cat_menu">
                {
                    props.el.name.map((e2, i) => {
                        const isActive = currCat === i ? "active" : "";
                        return (
                            <div onClick={() => isActive === "" && setCat(i)} className={isActive} key={i} data-id={props.el.id[i]}>
                                <h5>{props.el.name[i]}</h5>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{ transform: "translateX(-" + currCat + "00%)" }} className="material_parent">
                {
                    props.el.name.map((e2, i2) => {
                        var defaultMt = "";
                        const cat = props.el.id[i2].replace(/[0-9]/g, '');
                        Object.entries(props.mtls[cat]).map(([e3, i3], index) => {
                            if (props.activeCat === "chair" && e2.toLowerCase() === "pattes") {
                                defaultMt = { metal: "", wood: "" };
                                props.model.options[0].materials.map((e4, i4) => {
                                    if (e4.type === "metal") {
                                        defaultMt.metal = e4.default;
                                    } else if (e4.type === "wood") {
                                        defaultMt.wood = e4.default;
                                    }
                                });
                                props.model.options[1].materials.map((e4, i4) => {
                                    if (e4.type === "metal") {
                                        defaultMt.metal = e4.default;
                                    } else if (e4.type === "wood") {
                                        defaultMt.wood = e4.default;
                                    }
                                });

                            } else {
                                props.model.options[0].materials.map((e4, i4) => {
                                    if (props.el.id.includes(e4.type)) {
                                        defaultMt = e4.default;
                                    }
                                });
                            }
                        })
                        return <MaterialParent
                            iframe={props.iframe}
                            model={props.model}
                            default={defaultMt}
                            cat={cat}
                            categories={props.el.opt[i2]}
                            type={props.el.id[i2]}
                            mtls={props.mtls}
                            key={i2}
                            num={i2}
                            length={props.length}
                        />
                    })
                }
            </div>
        </div>
    )
}

function MaterialParent(props) {
    //console.log(props.default.metal!==undefined)
    const scrollToRef = useRef([]);
    /*const scrollParent = useRef([]);
    const [scrollEl] = useScrollIndicator({ onElement: true });
    const completion = useReadingScroll({element:scrollParent});
    console.log(completion)*/

    const [mt, setMt] = useState(props.default);
    const [mtCat, setMtCat] = useState(props.cat);
    //style={{height:"calc((100vh + 60px) / "+props.length+" - 200px )"}}
    const setCategory = (element, index) => {
        setMtCat(element)
        if (props.model.nameID.includes("chair")) {
            if (props.categories[index].includes("wood") || props.categories[index].includes("metal")) {
                const mod = props.model;

                //console.log(props.cat) 
                props.iframe.loadModel(mod.code, mod.options[index].code)
            }

        }
    }
    /*if (props.default.metal) {
        const sc = scrollToRef?.current[Object.values(props.mtls[mtCat]).map((e) => { return e.code }).indexOf(mt[mtCat])];
        sc?.parentNode.parentNode.scrollTo({ top: sc.offsetTop - (sc.offsetHeight / 2), behavior: "smooth" });
        //console.log(sc)
        //sc?.parentNode.scrollTop = sc?.offsetTop - 30;
    } else {
        const sc = scrollToRef?.current[Object.values(props.mtls[mtCat]).map((e) => { return e.code }).indexOf(mt)];
        //console.log(sc.parentNode.scrollTop)
        sc?.parentNode.parentNode.scrollTo({ top: sc.offsetTop - (sc.offsetHeight / 2), behavior: "smooth" });
        //console.log(sc.parentNode.scrollTop)
    }*/
    //console.log(scrollToRef)

    const catToFr = (name) => {
        var rtn;
        switch (name) {
            case "fabric":
                rtn = "tissus"
                break;
            case "leather":
                rtn = "cuirettes"
                break;
            case "wood":
                rtn = "bois"
                break;
            case "metal":
                rtn = "métal"
                break;
            case "porcelain":
                rtn = "porcelaine"
                break;
            case "glass":
                rtn = "verre"
                break;
        }

        return rtn;
    }
    //console.log(scrollEl);

    const [scroll, setScroll] = useState(0);
    const mtlRef = useRef();
    const scrollFunction = (e) => {
        const scrollH = e.scrollHeight - e.offsetHeight;
        const scrollPos = e.scrollTop;
        const res = Number((scrollPos / scrollH) * 100).toFixed(0)

        setScroll(res)
        //console.log(res);

    }
    const [gH, setgH] = useState(mtlRef.current?.parentNode.clientHeight);
    const [gW, setgW] = useState(mtlRef.current?.clientWidth);
    useEffect(() => {
        setgH(mtlRef.current?.parentNode.clientHeight)
        setgW(mtlRef.current?.clientWidth)
    }, [mtlRef]);
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setgH(mtlRef.current?.parentNode.clientHeight)
            setgW(mtlRef.current?.clientWidth)
        }, 50);

        window.addEventListener("resize", debouncedHandleResize);

        return _ => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    });
    //console.log(mtlRef.current.clientWidth);

    return (
        <div className="materials_parent">
            <div className="categories_parent">
                {
                    props.categories?.map((e, i) => {
                        const isActive = mtCat === e ? " active" : "";
                        const correctedIndex = props.cat === "metal" ? props.categories.length - 1 - i : i;
                        return <button key={i + "_btn"} onClick={() => mtCat !== e && setCategory(e, correctedIndex)} className={"catBtn" + isActive}>{catToFr(e)}</button>
                    })
                }
            </div>
            <div onScroll={(e) => scrollFunction(e.target)} className="materials_b">
                <div style={{ width: gW + "px", height: gH + "px" }} className="scrollGradient">
                    <span style={{ height: scroll + "%" }}></span>
                    <span style={{ height: 100 - scroll + "%" }}></span>
                </div>
                <div ref={mtlRef} className="materials">
                    {
                        Object.entries(props.mtls[mtCat]).map(([e, i], index) => {
                            const isActive = props.default.metal !== undefined ? mt[mtCat] === i.code ? " active" : "" : mt === i.code ? " active" : "";
                            const willShow = !props.model.nameID.includes("Soul") ? i.code === "VC" || i.code === "TG" ? false : true : true;
                            return (
                                <React.Fragment key={index}>
                                    {
                                        willShow && <Pastille
                                            iframe={props.iframe}
                                            mt={mt}
                                            setMt={setMt}
                                            active={isActive}
                                            type={props.type}
                                            cat={mtCat}
                                            name={e}
                                            code={i.code}
                                            isPattes={props.default.metal}
                                            scrollToRef={scrollToRef}
                                            index={index}
                                        />
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

function Pastille(props) {
    const name = props.name.replace(" ", "-").toLowerCase();
    const imgName = props.cat + "_" + name;
    const num = props.type.replace(/[^0-9]/g, '');
    const clickMt = () => {
        if (props.isPattes) {
            props.setMt({ ...props.mt, [props.cat]: props.code })
        } else {
            props.setMt(props.code)
        }
        //console.log(props.type);
        props.iframe.setMaterial(props.cat + num, props.code)
    }
    return (
        <div ref={(ref) => props.scrollToRef.current[props.index] = ref} onClick={() => clickMt()} data-id={props.code} className={"pastille" + props.active}>
            <img src={"./images/thumbnails/materials/" + imgName + ".jpg"} alt={props.name} />
        </div>
    )
}