import RightMenu from './RightMenu';
import MainMenu from './MainMenu';
import { useTransition, animated, useSpringRef, easings } from '@react-spring/web'
import { useEffect, useState } from 'react';
import TopMenu from './TopMenu';
import MobileBottom from './MobileBottom';

export default function MainPage(props) {

    const transRef = useSpringRef()
    const [mobileMode, setMobileMode] = useState("3d");
    const hide = mobileMode === "3d" ? " hide" : "";

    const pages = [
        ({ style }) => (
            <animated.div style={{ ...style }} className='transition'>
                <MainMenu categories={props.categories} active={props.active} setActive={props.setActive} setIndex={props.setIndex} data={props.data} model={props.model} setModel={props.setModel} />
            </animated.div>
        ),
        ({ style }) => (
            <animated.div style={{ ...style }} className='transition right'>
                <RightMenu categories={props.categories} active={props.active} data={props.data} model={props.model} setIndex={props.setIndex} />
            </animated.div>
        ),
    ]

    const transitions = useTransition(props.index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    })

    useEffect(() => {
        transRef.start()
        console.log(props.index);
    }, [props.index])

    return transitions(

        ((style, i) => {
            const Page = pages[i]
            return (
                <>
                    <TopMenu
                        setModel={props.setModel}
                        setActive={props.setActive}
                        setIndex={props.setIndex}
                        active={props.active}
                        categories={props.categories}
                    />
                    {
                        //<Page style={style} /> 
                        i == 0 ?
                            <animated.div style={{ ...style }} className='transition'>
                                <MainMenu categories={props.categories} active={props.active} setActive={props.setActive} setIndex={props.setIndex} data={props.data} model={props.model} setModel={props.setModel} />
                            </animated.div> :
                            <animated.div style={{ ...style }} className={'transition right' + hide}>
                                <RightMenu setMobileMode={setMobileMode} categories={props.categories} active={props.active} data={props.data} model={props.model} setIndex={props.setIndex} />
                            </animated.div>

                    }
                    {i == 1 && <MobileBottom mobileMode={mobileMode} setMobileMode={setMobileMode} />}
                </>
            )
        })
    )
}