import { useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react'

export default function TopMenu(props) {
    const [isOpen, setOpen] = useState(false)

    const setContent = (e) => {
        const el = e.target.dataset.id;
        props.setActive(el);
        setOpen(false);
        props.setIndex(0);
        props.setModel(null);
    }
    const mobileOpen = isOpen?" active":"";
    return (
        <>
            <div className='topbar mobile'>
                <img className="logo" src="../images/trica_logo.png" alt="logo trica" />
                <Hamburger size={25} className="hamburger" toggled={isOpen} toggle={setOpen} />
            </div>
            <div className={"gr_btn mobile"+mobileOpen}>
                {
                    Object.entries(props.categories).map(([el, i], index) => {
                        const isActive = props.active===el?" active":"";
                        return <button key={index} onClick={(e) => setContent(e)} data-id={el} className={"btn " + isActive}>{i.fr}</button>
                    })
                }
            </div>
        </>
    )
}